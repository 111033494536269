import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import AccountView from './views/account/AccountView';
import UsersListView from './views/users/UsersListView';
import DashboardView from './views/reports/DashboardView';
import LoginView from './views/auth/LoginView';
import NotFoundView from './views/errors/NotFoundView';
import DocsView from './views/api/DocsView';
import KeysView from './views/api/KeysView';
import VaultListView from './views/vault/VaultListView';

const routes = [
  {
    path: '',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Navigate to="/dashboard" /> },
      { path: 'account', element: <AccountView /> },
      { path: 'vault', element: <VaultListView /> },
      { path: 'users', element: <UsersListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'docs', element: <DocsView /> },
      { path: 'keys', element: <KeysView /> },
    ],
  },
  {
    path: '/auth',
    element: <MainLayout />,
    children: [
      { path: '', element: <Navigate to="/auth/login" /> },
      { path: 'login', element: <LoginView /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/404',
    element: <MainLayout />,
    children: [{ path: '', element: <NotFoundView /> }],
  },
  {
    path: '/login',
    element: <MainLayout />,
    children: [{ path: '', element: <Navigate to="/auth/login" /> }],
  },
  { path: '*', element: <Navigate to="/404" /> },
];

export default routes;
