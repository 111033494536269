import { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Page from '../../../components/Page';
import getKeys from '../../../actions/keys/getKeys';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function KeysView() {
  const classes = useStyles();
  const [keys] = useState(data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKeys());
  });

  return (
    <Page className={classes.root} title="API Keys">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results keys={keys} />
        </Box>
      </Container>
    </Page>
  );
}

export default KeysView;
