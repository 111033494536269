import { combineReducers } from 'redux';
import auth from './auth';
import keys from './keys';
import snack from './snack';
import stats from './stats';
import users from './users';
import updates from './updates';
import vault from './vault';

export default combineReducers({
  auth,
  keys,
  snack,
  stats,
  users,
  updates,
  vault,
});
