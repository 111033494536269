import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  Box, Button, Grid, TextField, Typography,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Alert } from '@material-ui/lab';
import formatBytes from '../../../utils/formatBytes';
import uploadFiles from '../../../actions/vault/uploadFiles';
import config from '../../../config';

function UploadDialog({ open, setOpen }) {
  const dispatch = useDispatch();
  const [size, setSize] = useState(0);

  return (
    <Formik
      initialValues={{
        files: [],
        description: '',
        tags: '',
        isPrivate: false,
        region: '',
      }}
      onSubmit={(values, actions) => {
        dispatch(
          uploadFiles({
            files: values.files,
            description: values.description,
            tags: values.tags,
            isPrivate: values.isPrivate,
            region: values.region,
            actions,
            setOpen,
          }),
        );
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        handleReset,
        isSubmitting,
        touched,
        setFieldValue,
        values,
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title" disableBackdropClick>
          <DialogTitle id="form-dialog-title">Upload Files</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select one or multiple files to upload.
              The same description and tags will be applied to all selected
              files, but those can be edited later.
            </DialogContentText>
            <Grid container alignItems="center">
              <Button color="primary" variant="contained" component="label">
                Upload File
                <input
                  type="file"
                  name="files"
                  onChange={(e) => {
                    window.size = 0;
                    for (let i = 0; i < e.target.files.length; i++) {
                      window.size += e.target.files[i].size;
                    }
                    setFieldValue('files', e.target.files);
                    setSize(window.size);
                  }}
                  multiple
                  hidden
                />
              </Button>
              <Box ml={3}>
                <Typography variant="body1">
                  {values.files
                    ? `${values.files.length} file${values.files.length > 1 ? 's' : ''} selected, ${formatBytes(
                      window.size || 0,
                    )}`
                    : 'No files selected'}
                </Typography>
              </Box>
            </Grid>
            <TextField
              error={Boolean(touched.description && errors.description)}
              helperText={errors.description || 'Description of the files (optional)'}
              margin="dense"
              name="description"
              label="Description"
              value={values.description}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(touched.tags && errors.tags)}
              helperText={errors.tags || 'Tags (separated by comma)'}
              margin="dense"
              name="tags"
              label="Tags"
              value={values.tags}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(touched.region && errors.region)}
              helperText={errors.region || 'Region (default: "world")'}
              margin="dense"
              name="region"
              label="Region"
              value={values.region}
              type="text"
              fullWidth
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Box>
              <FormControlLabel
                control={<Switch checked={values.isPrivate} onChange={handleChange} name="isPrivate" />}
                label={
                  values.isPrivate ? 'Private (requires authentication)' : 'Public (accessible without authentication)'
                }
              />
            </Box>
            {config.demo && (
              <Box pt={1}>
                <Alert severity="warning">Large files are disabled in demo mode. Maximum file size is 30MB.</Alert>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                window.size = 0;
                setSize(window.size);
                handleReset();
                setOpen(!open);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={
                isSubmitting || !values.files
                || values.files.length === 0 || (config.demo && size > 1024 * 1024 * 30)
              }
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

UploadDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default UploadDialog;
