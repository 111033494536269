const clipboardFix = (window, document, navigator) => {
  let textArea;

  const createTextArea = () => {
    textArea = document.createElement('textArea');
    document.body.appendChild(textArea);
    textArea.setAttribute('readonly', '');
    textArea.style = { position: 'absolute', left: '-9999px' };
  };

  const isOS = () => {
    return navigator.userAgent.match(/ipad|iphone/i);
  };

  const selectText = () => {
    let range;
    let selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  };

  const copyToClipboard = (text) => {
    let result = document.execCommand('copy');
    document.body.removeChild(textArea);

    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      // eslint-disable-next-line no-alert
      result = prompt(
        `Automatic copy to clipboard has been denied. ${
          isMac ? 'This is an issue with Safari. ' : ' '
        }Press ${copyHotkey} to manually copy.`,
        text,
      );
      if (!result) {
        return false;
      }
    }
    return true;
  };

  const copy = (text) => {
    createTextArea();
    textArea.value = text;
    selectText();
    return copyToClipboard(text);
  };

  window.Clipboard = { copy };
};

export default clipboardFix;
