import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box, Button, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Config from '../../../config';

function CopyDialog({ open, setOpen, shield = {} }) {
  const video = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [longURL, setLongURL] = useState('');
  const [shortURL, setShortURL] = useState('');
  const [download, setDownload] = useState(false);

  const fetUrls = () => {
    const file = shield;
    let url = `${Config.url}/api/vault/${file.shield}.${file.extension}`;

    const fetchShortUrl = () => {
      axios
        .post(`${Config.url}/api/shortner/add`, { target: url })
        .then((result) => {
          setShortURL(result.data.url);
        })
        .catch(() => {
          dispatch({ type: 'snack', content: 'error while shortening url', severity: 'error' });
        });
    };

    if (file.auth) {
      axios
        .post(`${Config.url}/api/vault/sign`, { shield: file.shield })
        .then((result) => {
          const parts = (result.data.url || '').split('?');
          const actionDownload = download ? '?action=download' : '';
          const andDownload = download ? '&action=download' : '';
          url = `${parts[0]}.${file.extension}${parts[1] ? `?${parts[1]}${andDownload}` : actionDownload}`;
          setLongURL(url);
          fetchShortUrl();
        })
        .catch(() => {
          dispatch({ type: 'snack', content: 'error while generating url', severity: 'error' });
        });
    } else {
      const actionDownload = download ? '?action=download' : '';
      url += actionDownload;
      setLongURL(url);
      fetchShortUrl();
    }
  };

  useEffect(() => {
    if (!shield.shield) {
      return;
    }
    fetUrls();
  }, [shield, download]);

  const copyUrlToClipboard = async ({ url }) => {
    const file = shield;
    setLoading(true);

    let permissionStatus;

    try {
      permissionStatus = await navigator.permissions.query({ name: 'clipboard-write' });
      if (permissionStatus.state !== 'granted' && permissionStatus.state !== 'prompt') {
        dispatch({ type: 'snack', content: 'no clipboard access: check permissions', severity: 'error' });
        setLoading(false);
        return;
      }
    } catch (e) {
      dispatch({ type: 'snack', content: 'error while copying to clipboard', severity: 'error' });
      setLoading(false);
      return;
    }

    try {
      // eslint-disable-next-line no-undef
      const result = navigator.clipboard.writeText(url);
      if (!result) {
        dispatch({ type: 'snack', content: 'error while copying to clipboard', severity: 'error' });
      }
    } catch (e) {
      dispatch({ type: 'snack', content: 'error while copying to clipboard', severity: 'error' });
      setLoading(false);
      return;
    }

    dispatch({ type: 'snack', content: `url copied to clipboard: ${file.name}`, severity: 'success' });
    setOpen(false);
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title" disableBackdropClick>
      <DialogTitle id="form-dialog-title">Copy URL</DialogTitle>
      <DialogContent>
        <DialogContentText>{`You are copying the URL of "${shield.name}".`}</DialogContentText>
        <TextField
          helperText={`Full URL: long shield${shield.auth ? ' + session token (private file)' : ' (public file)'}`}
          margin="dense"
          name="longURL"
          label="Full URL"
          value={longURL}
          type="text"
          fullWidth
          onFocus={(e) => e.target.select()}
        />
        <TextField
          helperText="Short URL: redirects to the full URL"
          margin="dense"
          name="shortURL"
          label="Short URL"
          value={shortURL}
          type="text"
          fullWidth
          onFocus={(e) => e.target.select()}
        />
        <Box>
          <FormControlLabel
            control={<Switch checked={download} onChange={() => setDownload(!download)} name="isPrivate" />}
            label={
              download ? 'Download active (will download on load)' : 'Download inactive (will open in browser)'
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => copyUrlToClipboard({ url: longURL })} color="primary" disabled={loading}>
          Copy full URL
        </Button>
        <Button onClick={() => copyUrlToClipboard({ url: shortURL })} color="primary" disabled={loading}>
          Copy short URL
        </Button>
        <Button
          onClick={() => {
            setOpen(!open);
            if (video && video.current) {
              video.current.pause();
            }
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CopyDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  shield: PropTypes.object,
};

export default CopyDialog;
