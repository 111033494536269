import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import editKey from '../../../actions/keys/editKey';

function EditDialog({ open, setOpen, keyValue = {} }) {
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        title: keyValue.title,
      }}
      onSubmit={(values, actions) => {
        dispatch(
          editKey({
            id: keyValue.id,
            title: values.title,
            actions,
            setOpen,
          }),
        );
      }}
    >
      {({
        errors, handleBlur, handleChange, handleSubmit, handleReset, isSubmitting, touched, values,
      }) => (
        <Dialog open={open} onClose={() => setOpen(!open)} aria-labelledby="form-dialog-title" disableBackdropClick>
          <DialogTitle id="form-dialog-title">
            Edit key
            {keyValue.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{`You are currently editing key ${keyValue.title}.`}</DialogContentText>
            <TextField
              autoFocus
              error={Boolean(touched.title && errors.title)}
              helperText={errors.title || 'Title of your API key'}
              margin="dense"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              label="Title"
              value={values.title}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleReset();
                setOpen(!open);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" disabled={isSubmitting}>
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}

EditDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  keyValue: PropTypes.object,
};

export default EditDialog;
